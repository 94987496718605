

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})
export default class EvrakTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() hideDetails!: string | boolean;
  @Prop() rules!: string;
  @Prop() dense!: string | boolean;

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isDense() {
    return this.dense || this.dense === "";
  }

  evrakTipleri = [
    {
      turKodu: "ICR_ACZ_BELG_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Aciz Belgesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_ADR_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Adres Araştırması Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_AVANS_IADE_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Avans İadesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_BRC_MUH_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Bakiye Borç Muhtırası Tebliği Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_BNK_PARA_ISTE_TLBI",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Bankadan Paranın İstenilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_KRH_0080",
      turTipi: "EVRTP00023",
      turAciklama: "Adli Birimlere Bildirim",
      aktif: "E",
      aciklama: "Bankalarda Hesap Açilmasi İçin Talep Yazısı",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_BRC_ITRZ_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Borca İtiraz Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_COCUK_TSL_SAHSI_MNSBT_TALEBI",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Çocuk Teslimi veya Çocukla Şahsi Münasebetin Tesisi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_DSY_ALACG_HCZ",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Dosya Alacağına Haciz Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_DOSYA_HSP_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Dosya Hesabı Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_DSY_ISLMDN_KLDR",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Dosyanın İşlemden Kaldırılması Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_DSY_YENLM_TLBI",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Dosyanın Yenileme Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_FAZLA_ODMNN_IADE_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Fazla Ödemenin İadesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_GNL_HCZ_BLDRM",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Genel Haciz Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_HCZ_KLDR",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Haciz Kaldırma Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_HCZ_TLMT_GON_TALB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Haciz Talimatı Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_HARC_IADE_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Harç İadesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_HRCN_TAHSIL_BLDRM",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Haricen Tahsil Bildirimi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_IBAN_NUMARASI_BILDIRIM_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "IBAN Numarası Bildirim Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_ODM_EMR_GOND_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İcra/Ödeme Emri Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "IFL_ALCK_KAYDI_DLKCE",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İflas Masasına Alacak Kaydı Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_IHTYTI_HCZ_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İhtiyati Haciz Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_IIK_150C_STS_ARZ_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İİK. 150/c Satışa Arz Şerhi Tesis Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_YTK_BLGS_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İİK.121.Maddeye Göre Yetki Belgesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_ISTHKK_HCZ_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "İstihkak Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_KYM_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Kıymet Takdiri Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_KYM_TAK_TLMT_GON_TALB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Kıymet Takdiri Talimatı Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_KYMT_TAKD_VAZGEC_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Kıymet Takdirinden Vazgeçilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_KYM_TAK_TBL_TALB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Kıymet Takdirinin Tebliği Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_EVR_HCZ_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Kıymetli Evrak Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_MAS_HCZ_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Maaş Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_ML_VAR_SOR_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Mal Varlığı Sorgulama Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_MHL_VSK_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Mehil Vesikası Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_HCZ_MHF",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Menkul Fiili Haciz Ve Muhafaza Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_SRG_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Mernis, Takbis, SGK, POLNET, Posta Çeki Hesabı Sorgu Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_MVD_HCZ",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Mevduat Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_ODEME_YAPILMASI_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Ödeme Yapılması Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_RHN_ACG_BELG_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Rehin Açığı Belgesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_STS_BEDEL_ODEME_TALEBI",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Satış Bedelinin Ödenmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_STS_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Satış Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_STS_TLMT_GON_TALB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Satış Talimatı Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_STS_DST_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Satışın Düşürülmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_SRK_HCZ_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Şirket Hissesi Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TAH_MUH_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Taahhadü Kabul Muhtırası Tebliği Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_THHT_EVR",
      turTipi: "EVRTP00001",
      turAciklama: "Dilekçe",
      aktif: "E",
      aciklama: "Taahhüt Evrağı",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_THL_TSL_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Tahliye ve Teslim Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TAKIP_KESINLESTIRME_EVR",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Takibin Kesinleştirilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "1",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TKY_BLG_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Takyidat Bilgisi Sorma Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TSN_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Taşınır Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TSMZ_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Taşınmaz Haczi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_THR_KAR",
      turTipi: "EVRTP00010",
      turAciklama: "Karar",
      aktif: "E",
      aciklama: "Tehir-i Icra Karari",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TMNT_IADE_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Teminatın İadesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TMLK_BILG_GRS_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Temlik Bilgisi Girişi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TRFK_MEN_SRH_KALD_TALEBI",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Trafikten Men Şerhinin Kaldırılması Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TRF_MEN_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Trafikten Men Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_UYP_ENTG_SOR_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "UYAP Entegrasyon Sorguları Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "CZM_VEKALETNAME",
      turTipi: "EVRTP00038",
      turAciklama: "Vekaletname",
      aktif: "E",
      aciklama: "Vekaletname",
      ilgiliKisiOlsunMu: "H",
      ilgiliEvrakOlsunMu: "H",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "1",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "CZM_VEKILLIKTEN_AZIL",
      turTipi: "EVRTP00001",
      turAciklama: "Dilekçe",
      aktif: "E",
      aciklama: "Vekillikten Azil Dilekçesi",
      ilgiliKisiOlsunMu: "E",
      ilgiliEvrakOlsunMu: "H",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "1",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "CZM_VEKILLIKTEN_CEKILME",
      turTipi: "EVRTP00001",
      turAciklama: "Dilekçe",
      aktif: "E",
      aciklama: "Vekillikten Çekilme Dilekçesi",
      ilgiliKisiOlsunMu: "E",
      ilgiliEvrakOlsunMu: "H",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "1",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_VRST_YTK_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Veraset Yetki Belgesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_YED_DEG_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Yediemin Değişikliği Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_YTK_BLG",
      turTipi: "EVRTP00021",
      turAciklama: "Tarafların Talep Ettikleri Yazılar",
      aktif: "E",
      aciklama: "Yetki Belgesi",
      ilgiliKisiOlsunMu: "E",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "2",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "H",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_YTK_DOS_GOND_TLB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "Yetkili İcra Dairesine Dosyanın Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_BLG_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "100.Madde Bilgi Toplanması Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_DVT_GON_TALB",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "103 Davetiye Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_21/2_TBL_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "21/2 Maddeye Göre Tebliğ Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_TBL_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "35.Maddeye Göre Tebliğ Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_1_HCZ_IHBR_CVB",
      turTipi: "EVRTP00022",
      turAciklama: "İcra Dairesi Genel Yazı",
      aktif: "E",
      aciklama: "89/1 Haciz İhbarnamesi Cevabı",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_1_HCZ_IHBR_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "89/1 Haciz İhbarnamesi Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_2_HCZ_IHBR_CVB",
      turTipi: "EVRTP00022",
      turAciklama: "İcra Dairesi Genel Yazı",
      aktif: "E",
      aciklama: "89/2 Haciz İhbarnamesi Cevabı",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_2_HCZ_IHBR_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "89/2 Haciz İhbarnamesi Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_3_HCZ_IHBR_CVB",
      turTipi: "EVRTP00022",
      turAciklama: "İcra Dairesi Genel Yazı",
      aktif: "E",
      aciklama: "89/3 Haciz İhbarnamesi Cevabı",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    },
    {
      turKodu: "ICR_89_3_HCZ_IHBR_TLP",
      turTipi: "EVRTP00083",
      turAciklama: "Talep",
      aktif: "E",
      aciklama: "89/3 Haciz İhbarnamesi Gönderilmesi Talebi",
      ilgiliKisiOlsunMu: "F",
      ilgiliEvrakOlsunMu: "F",
      islemTipi: "1",
      dosyaTipi: "0",
      statusYeni: "false",
      diskeKaydedilebilir: "E",
      diskeKaydedilebilirOnayOncesi: "E",
      imzaGerekli: "E",
      indekslensin: "false",
      yuksekOnemliIs: "0",
      onaySonrasiIsOlusturusun: "1",
      tebligataCiksinmi: "0",
      yuksekMahkemeyeIsDussun: "0",
      onayOncesiDagitimDuzenlenebilir: "E",
      fizikiGonderilebilir: "H",
      disUygulamaAktif: "E",
      ekImzasiZorunlumu: "H",
      ekListesiDegistirilebilir: "E",
      evrakListesindeGorunmesin: "H"
    }
  ]
}
