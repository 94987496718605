
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {PersonelEntity} from "@/entity/PersonelEntity";

@Component({
  components: {}
})

export default class PersonelPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() label!: string;
  @Prop() returnId!: string | boolean;
  @Prop() multiple!: string | boolean;
  @Prop() avukat!: string | boolean;
  @Prop() dense!: string | boolean;
  @Prop() hideDetails !: string | boolean;

  items: Array<PersonelEntity> = [];

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isReturnId() {
    return this.returnId || this.returnId === "";
  }

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  get isAvukat() {
    return this.avukat || this.avukat === "";
  }

  get isDense() {
    return this.dense || this.dense === "";
  }

  mounted() {
    this.load();
  }

  load() {
    if (this.isAvukat) {
      this.$http.get("/api/v1/personel/filter?type=avukat").then((response) => {
        this.items = response.data;
        this.input();
      });
    } else {
      this.$http.get("/api/v1/personel").then((response) => {
        this.items = response.data;
        this.input();
      });
    }
  }
}
