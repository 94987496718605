
import {Component, Mixins, Ref} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import EvrakTuruPicker from "@/components/pickers/EvrakTuruPicker.vue";
import GonderilecekDilekcePicker from "@/components/pickers/GonderilecekDilekcePicker.vue";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {IExcelDosya, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import {VForm} from "@/types";
import {DosyaSorguTipleriEnum} from "@/plugins/uyap-plugin/uyap/enum/DosyaSorguTipleriEnum";
import {
  HazirEvrakGonderTaskDataInterface
} from "@/plugins/uyap-plugin/store/modules/TaskDataInterface/HazirEvrakGonderTaskDataInterface";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {PersonelPicker, GonderilecekDilekcePicker, EvrakTuruPicker, GenelIslemFormWizard}
})
export default class HazirEvrakGonderFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genel-form') genelForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  valid = false;
  onFly = false;
  isKosulReadonly = false;
  search = '';

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Evrağı Gönder",
    "İşlemler Sıraya Alındı"
  ];

  headers = [
    {text: 'Banka', value: 'content'}
  ]

  islemDegerleri: HazirEvrakGonderTaskDataInterface = {
    kosul: null,
    evrakTur: null,
    dilekce: null,
    dilekceHtml: null,
    aciklama: null,
    ucuncuSahsaGonder: false,
    ozelAlanlar: []
  }

  kosullar = [
    {isim: 'Düşecek olan dosyalar', id: DosyaSorguTipleriEnum.DUSECEK_OLAN_DOSYALAR},
    {isim: 'Sgk aktif olan borçlular', id: DosyaSorguTipleriEnum.SGK_AKTIF_OLAN_DOSYALAR},
    {isim: 'EGM, Aracı olan borçlular', id: DosyaSorguTipleriEnum.EGM_ARACI_OLAN_BORCLULAR},
    {isim: 'Koşul yok', id: DosyaSorguTipleriEnum.KOSUL_YOK}
  ];

  onDilekceChange() {
    this.islemDegerleri.dilekceHtml = this.islemDegerleri.dilekce?.html;
    this.islemDegerleri.ozelAlanlar = [];
    if (this.islemDegerleri.dilekce && this.islemDegerleri.dilekce.code == 'maashaczi') {
      this.isKosulReadonly = true;
      this.islemDegerleri.kosul = DosyaSorguTipleriEnum.SGK_AKTIF_OLAN_DOSYALAR;
    } else
      this.isKosulReadonly = false;
    this.$forceUpdate();
  }

  async createTask() {
    if (this.form.validate()) {
      this.onFly = true;
      let tasks: Array<Task> = [];
      // @ts-ignore
      this.localValue.secilen_takipler.forEach((secilenTakip: IAvukatDosya | IExcelDosya) => {
        let task: Task = new Task();
        task.dosya_esas_no = secilenTakip.dosyaNo;
        task.birim_adi = secilenTakip.birimAdi;
        task.birim_id = secilenTakip?.birimId;
        task.uyap_dosya_acilis_tarihi = secilenTakip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(secilenTakip.dosyaAcilisTarihi)).toString() : "";
        task.dosya_id = secilenTakip.dosyaId;
        task.task_type_id = TaskTuru.hazir_evrak_gonder;
        task.data = this.islemDegerleri;
        tasks.push(task);
      });
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelForm.activeStep++;
      this.onFly = false;
    }
  }
}
