

import {Component, Vue} from "vue-property-decorator";
import HazirEvrakGonderFormWizard from "@/components/forms/form-wizard/eicrapro/HazirEvrakGonderFormWizard.vue";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, HazirEvrakGonderFormWizard}
})
export default class HazirEvrakGonderView extends Vue {
  taskTuru = TaskTuru;
}
